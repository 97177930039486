import React, { useState } from 'react';
import FAQAccord from '../GFlush';
import ContentImg from '../GSTTemp/ContentImg';
import ImgContent from '../GSTTemp/ImgContent';
import TDSAmount from './TDSAmount';
import TDSForm from './TDSForm';
import $ from 'jquery';

const TdS = () => {
  /* Img Content Component Data */
  const rentIcData = {
    id: 'tds-calculator',
    mt_div: 'mt-5',
    mt_img: '',
    header: 'What is a TDS calculator?',
    image: '/imgs/assited/tax-planning.png',
    alt_tag: 'Online TDS Calculator',
    points: [
      {
        head: '',
        content: `The TDS calculator is a straightforward 
                tool that enables you to determine whether you must deduct 
                TDS and, if so, how much.`,
        icon: true,
      },
      {
        head: '',
        content: `It enables our users to determine whether they had correctly 
                calculated their TDS and, in addition, it gives them a fundamental 
                understanding of the rules pertaining to TDS.`,
        icon: true,
      },
      {
        head: '',
        content: `Using our TDS calculator is easy and flexible, and if you have 
                any questions about income tax or tax preparation, feel free to contact 
                our specialists.`,
        icon: true,
      },
      {
        head: 'Benefit of using TDS Calculator',
        content: `It gives you the answer to how much you must withhold as 
                TDS in addition to the fundamental idea or guideline 
                governing that specific transaction.`,
        icon: true,
      },
      {
        head: '',
        content: `Users can utilize it to determine whether or not 
                the TDS deduction was correctly made.`,
        icon: true,
      },
      {
        head: '',
        content: `It is quickly accessible with just one click.`,
        icon: true,
      },
    ],
  };
  /*  Content Img Component Data */
  const rentCiData = {
    id: 'steps',
    background: 'main-content',
    mt_div: '',
    mt_img: '',
    header: 'How does TDS calculator work?',
    paragraph: `The TDS calculator aids in estimating the amount that you must withhold from a certain transaction.`,
    points: [
      {
        head: ``,
        content: `Based on the type of recipient and the circumstances of the transaction,
                 it is a simple and useful tool to determine the TDS amount.`,
        icon: true,
      },
      {
        head: `The steps listed below must be followed in order to calculate TDS:`,
        content: `Choose the recipient type: individual, sole proprietor, HUF,
                 or another option such as a firm, etc.`,
        icon: true,
      },
      {
        content: `If the person from whose PAN TDS is to be withheld has a valid 
                PAN, choose yes; otherwise, choose no.`,
        icon: true,
      },
      {
        content: `Sum of money that will be given to the receiver.`,
        icon: true,
      },
      {
        head: ``,
        content: `Kind of payment`,
        icon: true,
      },
      {
        content: `You are now prepared with the needed TDS deduction. `,
        icon: true,
      },
    ],
    image: '/imgs/registration/shop/shop-reg.png',
    alt_tag: 'Steps For TDS Calculator',
  };
  /* FaQ data */
  var RentGenFAQ = [
    {
      header:
        "If the person doesn't have a Pan Card, why does the TDS amount change?",
      body: [
        {
          content: `TDS may be charged at a rate of 20% or more if you don't 
                    have a Pan Card. However, you will be required to pay 
                    TDS at a rate of 10%.`,
          icon: true,
        },
        {
          content: `Note: Please be aware that since the amount won't appear on
                     Form 26AS if you don't have a Pan Card, you 
                     can have trouble claiming TDS.`,
          icon: true,
        },
      ],
    },
    // 2
    {
      header: 'How Do You Determine the TDS Amount?',
      body: [
        {
          content: `The 'average rate' of income tax is used to calculate TDS 
                    deductions from employee salaries. It will be determined in the way 
                    described below: The average income tax rate is the amount of income 
                    tax due (calculated using slab rates) divided by the employee's anticipated 
                    earnings for the fiscal year.`,
          icon: false,
        },
      ],
    },
    {
      header: `When should the TDS payment be made?`,
      body: [
        {
          content: `TDS is due every month on the seventh for payments made the
                     month prior, and on the thirty-first day of April for payments 
                     made the month prior.`,
          icon: true,
        },
      ],
    },
  ];
  const [currentStep, setCurrentStep] = useState(1);
  const [thresholdLimit, setThresholdLimit] = useState('');
  const [tdsRate, setTdsRate] = useState(0);
  const [resultMsg, setResultMsg] = useState('');
  const [tdsAmount, setTdsAmount] = useState(0);
  const [recipient, setRecipient] = useState(0);

  //state for form data
  const [formData, setFormData] = useState({
    // recipient: "",
    pan_available: '',
    amount_of_payment: '',
    nature_of_payment: '',
  });

  // console.log(formData);

  // const [receiptData, setReceiptData] = useState(false);

  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };
  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };
  const tds_rates = () => {
    switch (formData.nature_of_payment) {
      case '194J1':
        setThresholdLimit('30,000');
        if (formData.recipient === 1 && formData.pan_available === 1) {
          setTdsRate(0.1);
        } else if (formData.recipient === 2 && formData.pan_available === 1) {
          setTdsRate(0.1);
        } else {
          setTdsRate(0.2);
        }
        setResultMsg(
          'If gross payment to the party during the F.Y. exceeds Rs. 30,000 then TDS will be applicable @ 10 %.'
        );
        break;
      case '194J2':
        setThresholdLimit('30,000');
        if (formData.recipient === 1 && formData.pan_available === 1) {
          setTdsRate(0.02);
        } else if (formData.recipient === 2 && formData.pan_available === 1) {
          setTdsRate(0.02);
        } else {
          setTdsRate(0.2);
        }
        setResultMsg(
          'If gross payment to the party during the F.Y. exceeds Rs. 30,000 then TDS will be applicable @ 2%. '
        );
        break;
      case '194Ia':
        setThresholdLimit('2,40,000');
        if (formData.recipient === 1 && formData.pan_available === 1) {
          setTdsRate(0.02);
        } else if (formData.recipient === 2 && formData.pan_available === 1) {
          setTdsRate(0.02);
        } else {
          setTdsRate(0.2);
        }
        setResultMsg(
          'If gross payment to the party during the F.Y. exceeds Rs. 2,40,000 then TDS will be applicable @ 2% in case of Plant& machinery and 10 % in case of land & building or furniture & fitting.'
        );
        break;
      case '194Ib':
        setThresholdLimit('2,40,000');
        if (formData.recipient === 1 && formData.pan_available === 1) {
          setTdsRate(0.1);
        } else if (formData.recipient === 2 && formData.pan_available === 1) {
          setTdsRate(0.1);
        } else {
          setTdsRate(0.2);
        }
        setResultMsg(
          'If gross payment to the party during the F.Y. exceeds Rs. 2,40,000 then TDS will be applicable @ 2% in case of Plant& machinery and 10 % in case of land & building or furniture & fitting.'
        );
        break;
      case '194C_sp':
        setThresholdLimit('30,000');
        if (formData.recipient === 1 && formData.pan_available === 1) {
          setTdsRate(0.01);
        } else if (formData.recipient === 2 && formData.pan_available === 1) {
          setTdsRate(0.02);
        } else {
          setTdsRate(0.2);
        }
        setResultMsg(
          'If gross payment to the party during the F.Y. exceeds Rs. 1,00,000 then TDS will be applicable @ 1% for individuals and HUF and 2% for others. Also, if single payment exceeds Rs. 30,000 then also TDS will be applicable at the same rate.'
        );
        break;
      case '194C_fy':
        setThresholdLimit('1,00,000');
        if (formData.recipient === 1 && formData.pan_available === 1) {
          setTdsRate(0.01);
        } else if (formData.recipient === 2 && formData.pan_available === 1) {
          setTdsRate(0.02);
        } else {
          setTdsRate(0.2);
        }
        setResultMsg(
          'If gross payment to the party during the F.Y. exceeds Rs. 1,00,000 then TDS will be applicable @ 1% for individuals and HUF and 2% for others. Also, if single payment exceeds Rs. 30,000 then also TDS will be applicable at the same rate.'
        );
        break;
      case '194H':
        setThresholdLimit('15,000');
        if (formData.recipient === 1 && formData.pan_available === 1) {
          setTdsRate(0.05);
        } else if (formData.recipient === 2 && formData.pan_available === 1) {
          setTdsRate(0.05);
        } else {
          setTdsRate(0.2);
        }
        setResultMsg(
          'If gross payment to the party during the F.Y. exceeds Rs. 15,000 then TDS will be applicable @ 5%.'
        );
        break;
      case '194A1':
        setThresholdLimit('5,000');
        if (formData.recipient === 1 && formData.pan_available === 1) {
          setTdsRate(0.1);
        } else if (formData.recipient === 2 && formData.pan_available === 1) {
          setTdsRate(0.1);
        } else {
          setTdsRate(0.2);
        }
        setResultMsg(
          'If gross payment to the party during the F.Y. exceeds Rs. 5,000 then TDS will be applicable @ 10 %.'
        );
        break;
      case '193':
        setThresholdLimit('10,000');
        if (formData.recipient === 1 && formData.pan_available === 1) {
          setTdsRate(0.1);
        } else if (formData.recipient === 2 && formData.pan_available === 1) {
          setTdsRate(0.1);
        } else {
          setTdsRate(0.2);
        }
        setResultMsg(
          'If gross payment to the party during the F.Y. exceeds Rs. 10,000 then TDS will be applicable @ 10 %. In case where debentures are issued by the listed companies, no TDS shall be deducted upto Rs. 5000/-'
        );
        break;
      case '194':
        setThresholdLimit('5,000');
        if (formData.recipient === 1 && formData.pan_available === 1) {
          setTdsRate(0.1);
        } else if (formData.recipient === 2 && formData.pan_available === 1) {
          setTdsRate(0.1);
        } else {
          setTdsRate(0.2);
        }
        setResultMsg(
          'If gross payment to the party during the F.Y. exceeds Rs. 5,000/- then TDS will be applicable @ 10 %. '
        );
        break;
      case '194A2':
        setThresholdLimit('40,000');
        if (formData.recipient === 1 && formData.pan_available === 1) {
          setTdsRate(0.1);
        } else if (formData.recipient === 2 && formData.pan_available === 1) {
          setTdsRate(0.1);
        } else {
          setTdsRate(0.2);
        }
        setResultMsg(
          'If gross payment to the party during the F.Y. exceeds Rs. 40,000 then TDS will be applicable @ 10 %. For senior citizen this limit is Rs. 50,000.'
        );
        break;
      case '194B':
        setThresholdLimit('10,000');
        if (formData.recipient === 1 && formData.pan_available === 1) {
          setTdsRate(0.3);
        } else if (formData.recipient === 2 && formData.pan_available === 1) {
          setTdsRate(0.3);
        } else {
          setTdsRate(0.3);
        }
        setResultMsg(
          ' If gross payment to the party during the F.Y. exceeds Rs. 10,000 then TDS will be applicable @ 30 %.  '
        );
        break;
      case '194BB':
        setThresholdLimit('10,000');
        if (formData.recipient === 1 && formData.pan_available === 1) {
          setTdsRate(0.3);
        } else if (formData.recipient === 2 && formData.pan_available === 1) {
          setTdsRate(0.3);
        } else {
          setTdsRate(0.3);
        }
        setResultMsg(
          'If gross payment to the party during the F.Y. exceeds Rs. 10,000 then TDS will be applicable @ 30 %. '
        );
        break;
      case '194IA':
        setThresholdLimit('49,99,999');
        if (formData.recipient === 1 && formData.pan_available === 1) {
          setTdsRate(0.01);
        } else if (formData.recipient === 2 && formData.pan_available === 1) {
          setTdsRate(0.01);
        } else {
          setTdsRate(0.2);
        }
        setResultMsg(
          'Rule of TDS needs to be changed :If gross payment to the party during the F.Y. is Rs. 50,00,000 or more then TDS will be applicable @ 1 %.'
        );
        break;
      case '194IB':
        setThresholdLimit('50,000');
        if (formData.recipient === 1 && formData.pan_available === 1) {
          setTdsRate(0.05);
        } else if (formData.recipient === 2) {
          setTdsRate(0);
        } else {
          setTdsRate(0.05);
        }
        setResultMsg(
          'If monthly rent payment to the landlord exceeds Rs. 50,000 then TDS will be applicable @ 5 %.  In case, PAN not available, then provisions are different. For better understanding and complete law, you may write us on support@tax2win.in.'
        );
        break;
      case '192A':
        setThresholdLimit('50,000');
        if (formData.recipient === 1 && formData.pan_available === 1) {
          setTdsRate(0.1);
        } else if (formData.recipient === 2) {
          setTdsRate(0);
        } else {
          setTdsRate(0.3);
        }
        setResultMsg(
          'If gross withdrawal before 5 year continuous services during the F.Y. exceeds Rs. 50,000 then TDS will be applicable @ 10 %. '
        );
        break;
      case '194D':
        setThresholdLimit('15,000');
        if (formData.recipient === 1 && formData.pan_available === 1) {
          setTdsRate(0.05);
        } else if (formData.recipient === 2 && formData.pan_available === 1) {
          setTdsRate(0.1);
        } else {
          setTdsRate(0.2);
        }
        setResultMsg(
          'If gross payment to the party during the F.Y. exceeds Rs. 15,000 then TDS will be applicable @ 5 %. '
        );
        break;
      case '194DA':
        setThresholdLimit('99,999');
        if (formData.recipient === 1 && formData.pan_available === 1) {
          setTdsRate(0.05);
        } else if (formData.recipient === 2 && formData.pan_available === 1) {
          setTdsRate(0.05);
        } else {
          setTdsRate(0.2);
        }
        setResultMsg(
          'Rule of TDS needs to be changed : In case PAN is not available then TDS would be applicable at higher rates. If gross payment to the party during the F.Y. is Rs. 1,00,000 or more then TDS will be applicable @ 5 % & it is not applicable if amount is exempt u/s 10(10D).'
        );
        break;
      case '194O':
        setThresholdLimit('5,00,000');
        if (formData.recipient === 1 && formData.pan_available === 1) {
          setTdsRate(0.01);
        } else if (formData.recipient === 2 && formData.pan_available === 1) {
          setTdsRate(0.01);
        } else {
          setTdsRate(0.05);
        }
        setResultMsg(
          'If sale of goods or provision of services  during the F.Y. exceeds Rs. 5,00,000 then TDS will be applicable @ 1 %. '
        );
        break;
      case '194K':
        setThresholdLimit('5,000');
        if (formData.recipient === 1 && formData.pan_available === 1) {
          setTdsRate(0.1);
        } else if (formData.recipient === 2 && formData.pan_available === 1) {
          setTdsRate(0.1);
        } else {
          setTdsRate(0.2);
        }
        setResultMsg(
          'If dividend amount during the F.Y. exceeds Rs. 5,000 then TDS will be applicable @ 10%. No TDS to be deducted on Income from Capital Gains on sale of MFs'
        );
        break;

      default:
        <div></div>;
        break;
    }
  };

  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = (e) => {
    // input value from the form
    const { name, value } = e.target;
    //updating for data state taking previous state and then adding new value to create new object
    if (e.target.checked) {
      setRecipient(e.target.value);
    }
    setFormData({ ...formData, [name]: value });

    // localStorage.setItem('pan_available', value);
    if (formData.nature_of_payment !== '') {
      tds_rates();
    }
  };
  // useEffect(() => {
  // }, [formData, recipient]);

  const check_payment = () => {
    var pay_flag = true;
    var err_msg = '';
    if (formData.amount_of_payment.length === 0) {
      err_msg = 'Please Enter Amount';
      pay_flag = false;
    } else if (
      parseInt(formData.amount_of_payment) < 0 ||
      parseInt(formData.amount_of_payment) > 10000000
    ) {
      err_msg = 'Amount Must Be 0 - 1,00,00,000';
      pay_flag = false;
    }
    $('#amount_of_payment').after(
      '<div class="error-message">' + err_msg + '</div>'
    );
    return pay_flag;
  };
  // const getCurrencyFormatted = (t, e = !1) => {
  //   var i = (t = t.toString().replace(/[^0-9.]+/g, '')).split('.');
  //   return (
  //     (t = i[0]),
  //     e && null != i[1] && (t += '.' + i[1].substring(0, 2)),
  //     parseInt(t) < 0 ? 0 : Intl.NumberFormat('en-IN').format(t)
  //   );
  // };
  // const addCommas = (t, e = !1) => {
  //   if ('' !== t) var curr = '';
  //   return (
  //     (curr = getCurrencyFormatted(t, e)),
  //     'NaN' === curr || parseInt(curr) < 0 ? 0 : curr
  //   );
  // };
  const check_sec = () => {
    var sec_flag = true;
    var err_msg = '';
    if (formData.nature_of_payment.length === 0) {
      err_msg = 'Please select Nature of Payment';
      sec_flag = false;
    }
    $('#nature_of_payment').after(
      '<div class="error-message">' + err_msg + '</div>'
    );
    return sec_flag;
  };
  const calc = () => {
    var pan_absent_msg = '';
    if (formData.pan_available === 2) {
      pan_absent_msg =
        'In case PAN is not available then TDS would be applicable at higher rates. ';
    }
    $('#result_msg').text(pan_absent_msg + resultMsg);
    if (
      parseInt(formData.amount_of_payment) >
      parseInt(thresholdLimit.replace(/[^0-9.]/g, ''))
    ) {
      setTdsAmount(formData.amount_of_payment * tdsRate);
    }
    // $('#tds_amount').html(
    //     '<i class="fa fa-inr" aria-hidden="true"></i> ' +
    //     addCommas(parseFloat(tds_amount).toFixed(2), true)
    // );
    // $('#rule_of_tds').show();
  };

  const renderSwitch = (param) => {
    switch (param) {
      // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
      case 1:
        return (
          <TDSForm
            nextStep={nextStep}
            handleFormData={handleInputData}
            values={formData}
            setFormData={setFormData}
            handleCalculate={handleCalculate}
            recipient={recipient}
          />
        );
      // case 2 to show stepTwo form passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
      case 2:
        return (
          <TDSAmount
            prevStep={prevStep}
            tdsAmount={tdsAmount}
            resultMsg={resultMsg}
          />
        );

      // default case to show nothing
      default:
        return <div></div>;
    }
  };
  const handleCalculate = (e) => {
    $('.error-message').remove();
    // var paid_amount = addCommas(formData.amount_of_payment.replace(/[^0-9.]/g, ''));
    // setFormData((prevState) => ({
    //     ...prevState,
    //     ['amount_of_payment']: paid_amount,
    // }));
    if (check_payment() && check_sec()) {
      calc();
      nextStep();
      // alert(tdsAmount);
      // $('.calculate').addClass('hidden');
      // $('.result_area').removeClass('hidden');
    }
  };

  return (
    <>
      <section className='tds-calculator'>
        <div className='container  mt-5'>
          <div className='row house-rent-receipt mt-5'>
            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center p-0 mt-3 mb-2'>
              <div className=' px-0 pt-4 pb-0 mt-5 mb-3'>
                {/* <h2 id='heading' className='mt-5'>
                            Rent Receipt Generator
                        </h2>
                        <p>Fill all form field to go to next step</p> */}
                <form id='msform'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='rent-text'>
                        <h4 className='rent-head section-header text-white'>
                          All-In-One TDS Calculator
                          <div className='bar'></div>
                        </h4>
                        <p className='tds-paragraph text-white'>
                          Simplified instantaneous TDS calculation
                        </p>
                        <p className='tds-paragraph text-white'>
                          It is essential to calculate TDS accurately?
                        </p>
                        <p className='tds-paragraph text-white '>
                          TODAYFILINGS has conducted in-depth study on a number
                          of TDS-related topics so you may effectively arrange
                          your finances and save money on taxes. For
                          individuals, HUFs, sole proprietors, and others, this
                          calculator is perfect. This tool has been updated to
                          reflect the most recent TDS rates and rules.
                        </p>
                      </div>
                      {/* <div className='rent-img'>
                                                <img
                                                    src='/imgs/registration/tax/tax-img-slider.png'
                                                    alt='Generate Free Online Rent Receipt'
                                                />
                                            </div> */}
                    </div>

                    <div className='col-md-6 mt-5'>
                      {renderSwitch(currentStep)}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ImgContent item={rentIcData} />

      <ContentImg CISection_data={rentCiData} />
      <section className='rent-faq'>
        <FAQAccord items={RentGenFAQ} />
      </section>
    </>
  );
};
export default TdS;
