import React from 'react'
import Layout from '../../components/Layout'
import { Seo } from '../../components/seo'
import TdS from '../../components/TDSCalculator/TDS'

export default function TDSCalculator() {
    return (
      <>
        <Seo
          title='TDS Calculator'
          keywords='Keywords'
          description='Description'
          name='TODAYFILINGS'
          type='Website'
          author='TODAYFILINGS'
        />
        <Layout>
          <TdS />
        </Layout>
      </>
    );
}
