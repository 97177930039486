import React from 'react';

const TDSAmount = ({ prevStep, tdsAmount, resultMsg }) => {
  return (
    <div className='row tds-amount'>
      <div className='col-md-12 card'>
        <div className='col-md-3'>
          <button
            className='btn btn-sm  form-prev-btn btn-primary'
            onClick={prevStep}
          >
            <i className='bi bi-arrow-left'></i>Back
          </button>
        </div>
        <div className='tax-input-block-border'>
          <div className='result'>
            <div>
              <strong>TDS Amount: </strong>
              <br />
              <span id='tds_amount'>
                <i className='fa fa-inr' aria-hidden='true'></i> ₹ {tdsAmount}
              </span>
            </div>
          </div>
          <div id='rule_of_tds'>
            <label htmlFor='Rules'>
              <strong>Rule of TDS: </strong>
            </label>
            <span id='result_msg'>{resultMsg}</span>
          </div>

          <button
            style={{ marginTop: '50px' }}
            className='btn btn-md form-next-btn btn-success'
          >
            File ITR Now <i className='bi bi-arrow-right'></i>
          </button>
          {/* <Link to='/mortgages/itrfiling' className='btn btn-md form-next-btn btn-success m-2'>
              File ITR Now <i className='bi bi-arrow-right'></i>
            </Link> */}
        </div>
      </div>
    </div>
  );
};

export default TDSAmount;
