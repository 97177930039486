import React from 'react';

const TDSForm = ({ handleFormData, values, handleCalculate, recipient }) => {
  const NatureOfPayment = [
    {
      value: '000',
      code: `Select State`,
    },
    {
      value: '192A',
      code: `Section 192A - Payment of accumulated PF balance to an employee`,
    },
    {
      value: '193',
      code: `Section 193 - Interest on Securities`,
    },
    {
      value: '194',
      code: `Section 194 - Dividend other than the Dividend as referred to in Section 115-O`,
    },
    {
      value: '194A1',
      code: `Section 194A - Interest other than Banks`,
    },
    {
      value: '194A2',
      code: `Section 194A - Interest from Banks`,
    },
    {
      value: '194B',
      code: `Section 194B - Winnings from Lotteries, Crossword Puzzles etc.`,
    },
    {
      value: '194BB',
      code: `Section 194BB - Income by way of Winnings from Horse Races`,
    },
    {
      value: '194C_sp',
      code: `Section 194C - Payment to Contractor/Sub-Contractor ( In case of single payment )`,
    },
    {
      value: '194C_fy',
      code: `Section 194C - Payment to Contractor/Sub-Contractor ( In case of during F.Y. )`,
    },
    {
      value: '194D',
      code: `Section 194D - TDS on insurance commission`,
    },
    {
      value: '194DA',
      code: `Section 194DA - TDS on Payment in Respect of Life Insurance Policy`,
    },
    {
      value: '194H',
      code: `Section 194H - Commission or Brokerage`,
    },
    {
      value: '194I(a)',
      code: `Section 194I(a) - Rent on Plant & Machinery`,
    },
    {
      value: '194I(b)',
      code: `Section 194I(b) - Rent on Land & building or Furniture & Fitting`,
    },
    {
      value: '194IA',
      code: `Section 194IA - Payment on transfer of certain Immovable Property 
            other than agricultural land`,
    },
    {
      value: '194IB',
      code: `Section 194IB - Rent Payment to Landlord by Individuals`,
    },
    {
      value: '194J1',
      code: `Section 194J - Fees for Professional Services / Royalty etc. (Normally in all cases)`,
    },
    {
      value: '194J2',
      code: `Section 194J - Fees for technical services, Fees to Call centre operator`,
    },
    {
      value: '194K',
      code: `Section 194K - TDS on income from mutual fund units (Dividend income)`,
    },
    {
      value: '194O',
      code: `Section 194O - TDS on Payment by E-commerce Operator to E-commerce participant`,
    },
  ];

  return (
    <div id='tds-form' className='row other-details'>
      <div className='col-md-12 card'>
        <div className='tax-input-block-border'>
          <form>
            <div className='row mt-5'>
              <div className='col-md-6 mb-3'>
                <label htmlFor='Amount of Payment' className='form-label'>
                  Amount of Payment
                  <span className='text-danger required'>*</span>
                </label>
                <input
                  type='text'
                  className='rent-input form-control'
                  id='amount_of_payment'
                  name='amount_of_payment'
                  required='required'
                  autoComplete='off'
                  value={values.amount_of_payment}
                  onChange={handleFormData}
                  placeholder='5,00,000'
                />
              </div>
              <div className='col-md-6 '>
                <label htmlFor='PAN of Recipient' className='form-label'>
                  PAN of Recipient is Available
                </label>

                <select
                  value={values.pan_available}
                  name='pan_available'
                  defaultValue='0'
                  onChange={handleFormData}
                  className='form-select'
                  aria-label='Default select example'
                >
                  <option value='0'>Select</option>
                  <option value='1'>Yes</option>
                  <option value='2'>No</option>
                </select>
              </div>
            </div>
            <div className=' col-md-12 mb-3'>
              <label htmlFor='form-label'>
                Nature Of Payment<span className='text-danger required'>*</span>
              </label>
              <div className='form-group'>
                <select
                  name='nature_of_payment'
                  required='required'
                  id='nature_of_payment'
                  className='form-control'
                  autoComplete='off'
                  value={values.nature_of_payment}
                  onChange={handleFormData}
                >
                  {NatureOfPayment.map((nature, index) => (
                    <option value={nature.value} key={index}>
                      {nature.code}
                    </option>
                  ))}
                </select>
                {/* <select name="nature_of_payment" defaultValue="000" required="required" id="nature_of_payment" className="form-control" autoComplete="off"
                                    value={values.nature_of_payment}
                                    onChange={handleFormData}>

                                    {NatureOfPayment.map((nature, index) => (
                                        <option value={nature.value} key={index}>{nature.code}</option>
                                    ))}

                                </select> */}
              </div>
            </div>
            <div className='living'>
              <label htmlFor='Living' className='form-label living-label'>
                <strong> Recipient:</strong>
              </label>
              <tbody>
                <tr>
                  <td>
                    <input
                      type='radio'
                      name='recipient'
                      className='tds_input'
                      id='1'
                      value='1'
                      checked={recipient === '1'}
                      // value={values.recipient}
                      onChange={handleFormData}
                    />
                    <span> Individual/HUF/Sole Proprietor</span>
                    <br />
                  </td>
                  <td>
                    <input
                      type='radio'
                      name='recipient'
                      className='tds_input'
                      id='2'
                      value='2'
                      checked={recipient === '2'}
                      // value={values.recipient}
                      onChange={handleFormData}
                    />
                    <span> Others</span>
                    <br />
                  </td>
                </tr>
              </tbody>
            </div>
          </form>

          <div className='col-md-4 '>
            <button
              type='button'
              className='btn btn-md form-next-btn btn-success m-2'
              id='tds_calculator'
              onClick={handleCalculate}
            >
              Calculate <i className='bi bi-arrow-right'></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TDSForm;
